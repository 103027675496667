<script setup>
import { GA_ACCOUNTS } from '@/config/analytics';

const runtimeConfig = useRuntimeConfig();
const { locale } = useI18n();
const { useUrl } = useUrls();
const testingStore = useTestingStore();
const sustainabilityStore = useSustainabilityStore();
const envData = runtimeConfig?.public;
const { isTrackingAllowed } = checkGdpr();
const { isExtraSmallScreen } = useMq();

const isGoogleTagManagerEnabled = testingStore.hasEnabledFeature('web_googleTagManager');
const isHotjarEnabled = testingStore.hasEnabledFeature('web_hotjar');

const COOKIESPOLICYURL = useUrl.getCookiePolicyUrl();
const SECURITYPRIVACYURL = useUrl.getSecurityPrivacyUrl();

const actualLanguage = locale?.value;

// @TODO: For some weird reason, the trustArc banner is being rendered twice on DOM, but script is only called once
const trustArcScript = [
  {
    async: true,
    crossorigin: 'anonymous',
    fetchpriority: 'high',
    src:
      'https://consent.trustarc.com/notice?domain=hostelworld.com'
      + '&c=teconsent'
      + '&gtm=1'
      + '&js=nj'
      + '&noticeType=bb'
      + `&language=${actualLanguage}`
      + '&language=true'
      + '&text=true'
      + `&cookieLink=${encodeURIComponent(`${COOKIESPOLICYURL}`)}&privacypolicylink=${encodeURIComponent(
        `${SECURITYPRIVACYURL}`,
      )}`,
  },
];

const googleHpa = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtag',
      defer: true,
      crossorigin: 'anonymous',
      src: `https://www.googletagmanager.com/gtag/js?id=${GA_ACCOUNTS[0].ID}`,
    },
  ]
  : [];

const gtm = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtm',
      defer: true,
      crossorigin: 'anonymous',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f?.parentNode?.insertBefore(j,f);
            })(window,document,'script','dataLayer','${envData.GTM_ID}');`,
    },
  ]
  : [];

const hotjar
  = isTrackingAllowed && isHotjarEnabled
    ? [
      {
        key: 'hotjar',
        async: true,
        defer: true,
        src: '//u.hwstatic.com/hw/hotjar/hotjar.js',
      },
    ]
    : [];

const buttonMerchantScirpt = toValue(isExtraSmallScreen)
  ? [
    {
      key: 'merchant-script',
      crossorigin: 'anonymous',
      children: `
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          window.ButtonWebConfig = {
            applicationId: 'app-2e632f0c7172fd31'
          };

          (function (u, s, e, b, t, n) {
            u['__bttnio'] = b; u[b] = u[b] || function () { (u[b].q = u[b].q || []).push(arguments) }; t = s.createElement(e); n = s.getElementsByTagName(e)[0]; t.async = 1; t.src = 'https://web.btncdn.com/v1/button.js'; n.parentNode.insertBefore(t, n);
          })(window, document, 'script', 'bttnio');
        }
      `,
    },
  ]
  : [];

const headerScripts = [...trustArcScript, ...googleHpa, ...gtm, ...hotjar, ...buttonMerchantScirpt];

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  script: headerScripts,
}));
</script>

<template>
  <div class="container">
    <main>
      <slot />
    </main>

    <ClientOnly>
      <LazyCommonHwCurrencyPicker />
      <LazyCommonHwSustainabilityPopup v-if="sustainabilityStore.isSustainabilityPopupOpen" />
    </ClientOnly>

    <CommonHwFooter />

    <ClientOnly>
      <LazyCommonHwCookieBar />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.container {
  background-color: $wds-color-black;
  color: $wds-color-white;
}

:deep(.modal-container .heading-text),
:deep(.modal-container .input) {
  color: $wds-color-ink-darker;
}
</style>
